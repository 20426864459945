import React from "react";

// import { useTranslation } from "react-i18next";
import { readState } from "@/__main__/app-state.mjs";
import GridList from "@/feature-reskin-probuilds-net/components/GridList.jsx";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import ProHeadshot from "@/feature-reskin-probuilds-net/components/ProHeadshot.jsx";
import playerImage from "@/feature-reskin-probuilds-net/utils/player-image.mjs";
import playerLink from "@/feature-reskin-probuilds-net/utils/player-link.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Pros() {
  // const { t } = useTranslation();
  const {
    lol: { proBuildPros = [] },
  } = useSnapshot(readState);

  const renderedPros = proBuildPros
    .filter((player) => player.fame > 2)
    .sort((a, b) => (b.fame || 0) - (a.fame || 0))
    .map((player, i) => ({
      link: playerLink(player.id),
      image: (
        <ProHeadshot
          src={playerImage(player.portraitImageUrl)}
          name={player.name}
          lazy={i > 50}
          className="item-image"
          size={40}
        />
      ),
      title: player.name,
    }));

  return (
    <Layout
    // title={t("probuildsnet:nav.probuilds", "Pros")}
    >
      <GridList items={renderedPros} />
    </Layout>
  );
}

export default Pros;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
